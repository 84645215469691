
@font-face {
	font-family: 'Open SansLight';
	src: url('../font/OpenSans-Light-webfont.eot');
	src: url('../font/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
	url('../font/OpenSans-Light-webfont.woff') format('woff'),
	url('../font/OpenSans-Light-webfont.ttf') format('truetype'),
	url('../font/OpenSans-Light-webfont.svg#Open SansLight') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Open SansRegular';
	src: url('../font/OpenSans-Regular-webfont.eot');
	src: url('../font/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../font/OpenSans-Regular-webfont.woff') format('woff'),
	url('../font/OpenSans-Regular-webfont.ttf') format('truetype'),
	url('../font/OpenSans-Regular-webfont.svg#Open SansRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Open SansSemibold';
	src: url('../font/OpenSans-Semibold-webfont.eot');
	src: url('../font/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../font/OpenSans-Semibold-webfont.woff') format('woff'),
	url('../font/OpenSans-Semibold-webfont.ttf') format('truetype'),
	url('../font/OpenSans-Semibold-webfont.svg#Open SansRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}


body{
	/* background:url(../../public/media/images/bg.jpg) center center fixed; */
	background-size:100% 100%;
	color:#474747 !important;
	
	font-family: 'Open SansRegular';
	font-size:14px;
	font-size:13px;
}

label{
	font-weight:normal !important;	
}
/****/
.logo{
	background:url(../../public/media/images/logo3.png);
	width:660px;
	height:50px;
	display:inline-block;
	margin-top:-15px;
	margin-left:-15px;
}

/****/
.container-slider {
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	/*margin-right: auto;
	margin-left: auto*/
	margin:0 0;
}
.slide-area{
	border:2px solid #9C0;
	margin-top:-20px;
	clear:both;
	
	
	/* ukuran slide */
	/* 1440 */
	/* 128 */

}

/****/
.judul-halaman{
	padding:10px 20px;
	font-family: 'Open SansSemibold';
	font-size:18px;
	color:#FFF;
	text-transform:uppercase;
}
.judul-halaman span img{
	vertical-align:middle;
}
.judul-halaman-bawah{
	height:10px;
	border-bottom:1px solid #f0f0f0;
	margin-bottom:10px;
}

.baris{
	margin-top:-10px;
	background:#F66;
	height:1px;
	border-bottom:1px solid #f0f0f0;
	margin-bottom:10px;
}

.judul-halaman span input[type = text] {
	height:30px;
	padding:0 5px;
	background:rgba(0,0,0,0.3);
	font-size:12px;
	
	font-family: 'Open SansLight';
	font-style:italic;
	border:1px solid #999;
}

/****/
.judul-cari{
	float:right;
}

/****/
.judul-section{
	color:#333333;
	font-family: 'Open SansSemibold';
	font-size:16px;
	text-transform:uppercase;
	height:40px;
	line-height:40px;
	border-top:1px solid #f0f0f0;
	border-bottom:1px solid #f0f0f0;
	background:#FFF;
}
.judul-section span img{
	margin-left:20px;
	margin-right:10px;
	vertical-align:middle;
}

/****/
.judul-box{
	color:#FFF;
	font-family: 'Open SansSemibold';
	font-size:16px;
	text-transform:uppercase;
	height:40px;
	line-height:40px;
	border-top:1px solid #f0f0f0;
	border-bottom:1px solid #f0f0f0;
	background:#FFF;
}
.judul-box span img{
	margin-left:10px;
	margin-right:0px;
	vertical-align:middle;
}

/****/
.konten{
	box-sizing:border-box;
	background:#FFF;
	/* border-bottom:4px solid #f0f0f0; */
	margin-bottom:10px;
	padding:0 20px;
	
	display:inline-block;
	width:100%;
}
.konten-data{
	padding:10px 20px;
	text-align:justify;
}
.konten-table{
	padding:10px 10px;
	border-left:	1px solid #f0f0ee;
	border-right:	1px solid #f0f0ee;
	border-bottom:	1px solid #00aeef;
	
}

/****/
.konten-detil{
	padding:0px 0px 0px ;
	border:1px solid #f0f0f0;
	margin-bottom:20px;
}
.konten-detil table{
	border-collapse:collapse;
	width:100%;
	border:1px solid #f0f0f0;
}
.konten-detil table td{
	padding:10px 10px;
	border:1px solid #d9f2fd;
	border:1px solid #f0f0f0;
	border-width:1px 0 1px 0;
	
}
.konten-detil table td:nth-child(1){
	width:200px;
}
.konten-detil table td:nth-child(2){
	width:20px;
}

/****/
.konten-monitoring{ display:inline-block; width:100%; margin-bottom:18px;}
.konten-monitoring table{
	border-collapse:collapse;
	width:100%;
}
.konten-monitoring table th{
	background:#b0b0b0;
	color:#FFF;
	text-align:center;
	padding:4px 7px;
	text-transform:uppercase;
	border:1px solid #f0f0f0;
}
.konten-monitoring table td{
	border:1px solid #f0f0f0;
	padding:4px 7px;
}

/****/
.konten-chart{
	box-sizing:border-box;
	background:#f2f3f0;
	border:1px solid #e1e2e0;
	border-width:1px 1px 4px 1px;
	margin-bottom:10px;
	padding:0 0 0 ;
	
	display:inline-block;
	width:100%;
	
}
.konten-chart #chart{
	border:2px solid green;
	width:80% !important;
	height:100% ;
	margin-top:-70px;
	margin-bottom:-60px;
}
.konten-chart canvas{
	border:2px solid red;
	height:400px;
	padding:-100px 0;
	width:100% !important;
	height:100%;
}



/****/
.atas-10{ margin-top:10px;}
.bawah-10{ margin-bottom:10px;}

.bg-course{		background:#00aeef url(../../public/media/images/bg-judul-halaman.png) left no-repeat;}
.bg-knowledge{	background:#7e7e7e url(../../public/media/images/bg-judul-halaman.png) left no-repeat;}
.bg-event{		background:#ef7600 url(../../public/media/images/bg-judul-halaman.png) left no-repeat;}

.bg-sub{		background:#1561ae url(../../public/media/images/bg-judul-halaman.png) left no-repeat; }

.bg-half-kanan{	background:#f9f9f8;}
.bg-half-kanan{
	background:#f9f8f7;
	/*background-color: #e8e8e8; 
	background-repeat: repeat-y;
	background: -webkit-gradient(linear, left top, right top, from(#e8e8e8), to(#f9f9f8)); 
	background: -webkit-linear-gradient(left, #e8e8e8, #f9f9f8); 
	background: -moz-linear-gradient(left, #e8e8e8, #f9f9f8); 
	background: -ms-linear-gradient(left, #e8e8e8, #f9f9f8); 
	background: -o-linear-gradient(left, #e8e8e8, #f9f9f8);*/
	
	border-left:4px solid #f0f0f0;
}
	
.bg-dashboard{ 	background:#FFF url(../../public/media/images/bg-judul-halaman.png) left no-repeat; color:#333;}
.bg-green{		background:#5cb151;}
.bg-green2{		background:#9BB051;}
.bg-violet{		background:#7a51b1;}
.bg-pink{		background:#ef00a3;}

.bg-1{		background:#1561ae;}
.bg-2{		background:#00aeef;}
.bg-3{		background:#ef7600;}

.bd-course{		border-left:2px solid #00aeef;}
.bd-course4{		border-left:4px solid #00aeef;}
.bd-event{		border-left:2px solid #ef7600;}
.bd-knowledge{	border-left:2px solid #7e7e7e;}
.bd-green{		border-left:2px solid #5cb151;}
.bd-green2{		border-left:2px solid #9BB051;}
.bd-pink{		border-left:2px solid #ef00a3;}
.bd-sub{		border-left:2px solid #1561ae;}

.bd-top-bottom{		border-bottom:4px solid #7e7e7e;}

.bd-1{		border-left:4px solid #78bbff;}
.bd-2{		border-left:4px solid #7cdbff;}
.bd-3{		border-left:4px solid #ffac5b;}

.area-1{		background:#d6eaff;}
.area-2{		background:#dcf5ff;}
.area-3{		background:#ffefdf;}



/****/
/* .course-area{} */
.course-item{
	margin-bottom:18px;
	padding-bottom:10px;
	border-bottom:1px solid #f0f0f0;
	background:#FFF;
}
.course-data{
	border-left:5px solid #1561ae;
	padding-left:20px;
	padding-right:20px;
	margin-top:10px;
}
.course-data-ditolak{
	border-left:5px solid #d9534f;
	padding-left:20px;
	padding-right:20px;
	margin-top:10px;
}

.course-item-home{
	margin-bottom:18px;
	padding-bottom:10px;
	padding:7px 0;
	border-bottom:0px solid #f0f0f0;
	background:#FFF;
}
.course-data-home{
	border-left:5px solid #1561ae;
	padding-left:10px;
	padding-right:20px;
	margin-top:10px;
}
.course-judul-border{
	border-bottom:2px solid #f3a656/*ef7600*/;
	margin-bottom:18px;
	width:60px;
}

.course-jam{
	color:#acacac;
	font-size:12px;
}
.course-jam img{
	vertical-align:middle;
	margin-right:5px;
}
.course-judul{
	font-family: 'Open SansSemibold';
	font-size:18px;
	text-transform:capitalize;
}
.course-judul-home{
	font-family: 'Open SansSemibold';
	font-size:18px;
	text-transform:capitalize;
	min-height:80px;
}

.course-judul2{
	font-family: 'Open SansSemibold';
	font-size:16px;
	margin-bottom:5px;
}
.course-judul a,
.course-judul-home a{
	color:#474747;
}
.course-isi{
	text-align:justify;
}
.course-isi-ditolak{
	text-align:justify;
	background:#fde8e7;
	padding:4px 10px;
	
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.course-isi-ditolak span{
	color:#d9534f;
	font-style:italic;
}

/* .course-thumb{} */
/* .course-thumb-detil{
	border:1px solid red;
} */
.course-thumb-detil img{
	width:100%;
}

.course-footnote{
	color:#76c0ff;
	padding-left:25px;
	margin-top:5px;
}

.course-footnote img:nth-child(2),
.course-footnote img:nth-child(3){
	margin-left:10px;
}

.course-footnote-home{
	color:#76c0ff;
	padding-left:10px;
	margin-top:5px;
}

.course-footnote-home img:nth-child(2),
.course-footnote-home img:nth-child(3){
	margin-left:10px;
}



/****/
.course-kolom{
	background:#f9f8f7/*f0f0f0*//*00aeef*//*efbf00*//*0078a3*/;
	padding-top:18px;
}
.course-kolom .col-md-6:nth-child(odd){
	border:1px solid red;
	background:#33C !important;
	padding-right:7px !important;
	
}
.course-kolom .col-md-6:nth-child(even){
	border:1px solid red;
	background:#33C !important;
	padding-left:8px !important;
	
}
.course-kolom-item{
	display:inline-block;
	margin-bottom:10px;
	padding-bottom:10px;
	padding:10px;
	
	border-bottom:1px solid #f0f0f0;
	
	background:#FFF;
	width:100%;
	
}
.course-kolom-item-home{
	display:inline-block;
	margin-bottom:10px;
	padding-bottom:10px;
	padding:10px;
	
	border-bottom:1px solid #f0f0f0;
	
	background:#FFF;
	width:100%;
	
}
.course-kolom-note{
	float:left;
	width:100px;
	color:#acacac;
	font-size:12px;
	
	text-align:right;
	padding-right:10px;
}
.course-kolom-note div{
	border:1px solid red;
	margin-bottom:6px;
}
.course-kolom-note img{
	margin-left:5px;
}
.course-kolom-judul{
	min-height:110px;
	min-height:170px;
	position:relative;
	float:right;
	width: -moz-calc(100% - 100px);
    width: -webkit-calc(100% - 100px);
    width: -o-calc(100% - 100px);
    width: calc(100% - 100px); 
	
	font-family: 'Open SansSemibold';
	font-size:18px;
	
	padding:0 10px;
	background:#CCC;
	border-left:1px solid #1561ae;

}
.course-kolom-kategori{
	background:#C60;
}
.course-kolom-nama{
	background:#F6F;
	padding-bottom:20px;
}

/****/
.view-detil{
	font-family: 'Open SansSemibold';
	font-size:12px;
	text-transform:uppercase;
	color:#777777;
	background: url(../../public/media/images/icon-detil.png) right no-repeat;
	width:110px;
	height:20px;
	
	position:absolute;
	bottom:0;
	left:10px;
	
}
/*.view-detil2{
	font-family: 'Open SansSemibold';
	font-size:12px;
	text-transform:uppercase;
	color:#777777;
	background: url(../../public/media/images/icon-detil.png) right no-repeat;
	width:110px;
	height:20px;
	
	clear:both;
	float:right;
	display:inline-block;
	
	text-align:right;
	padding-right:30px;
	margin-top:10px;
	margin-bottom:10px;
	margin-right:20px;
	
}*/

/****/
.top5-course ul{
	padding: 0;
    list-style-type: none;
}
.top5-course ul li{
	
	border-bottom:1px solid #f0f0f0;
	padding:7px 0;
	line-height: normal;
}
.top5-course ul li span{
	width:30px;
	height:30px;
	display:inline-block;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	
	color:#FFF;
	text-align:center;
	line-height:30px;
	margin-right:10px;
	
	font-size:12px;
}
.top5-course ul li:nth-child(1) span{	background:#1561ae;}
.top5-course ul li:nth-child(2) span{	background:#00aeef;}
.top5-course ul li:nth-child(3) span{	background:#7e7e7e;}
.top5-course ul li:nth-child(4) span{	background:#efbf00;}
.top5-course ul li:nth-child(5) span{	background:#ef7600;}

/****/
.top5-knowledge ul{
	padding: 0;
    list-style-type: none;
}
.top5-knowledge ul li{
	
	border-bottom:1px solid #f0f0f0;
	padding:7px 0;
	line-height: normal;
	
}
.top5-knowledge ul li a{
	color:#474747;
}
.top5-knowledge ul li span{
	width:30px;
	height:30px;
	display:inline-block;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	
	color:#FFF;
	text-align:center;
	line-height:30px;
	margin-right:10px;
	
	background:#1561ae;
	font-size:12px;
}

/****/
/* .kalender-area{
	height:400px !important;
	background:#096;
} */

/****/
/* .event-area{
	height:400px !important;
	background:#096;
} */
.event-list{
	width:100%;
	padding:10px 0;
	display:inline-block;
	
	/* border-bottom:1px solid #f0f0f0; */
}
.event-empty{
	/* height:404px; */
	color:red !important;
	/*width:100%;
	padding:10px 0;
	display:inline-block;
	
	border-bottom:1px solid #f0f0f0;*/
}
.event-tgl{
	float:left;
	width:70px;
	
	font-family: 'Open SansSemibold';
	font-size:14px;
	color:#ef7600;
	text-transform:uppercase;
}
.event-isi{
	
	float:left;
	
	width: -moz-calc(100% - 70px);
    width: -webkit-calc(100% - 70px);
    width: -o-calc(100% - 70px);
    width: calc(100% - 70px); 
	
	text-align:justify;
}
.event-isi a{
	color:#474747;
}

/****/
.event-summary{
	display:inline-block;
	margin-top:5px;
	width:100%;
}
.event-summary-detil{
	display:inline-block;
	margin-top:5px;
	width:100%;
	border-bottom:1px solid #f0f0f0;
	padding-bottom:10px;
	margin-bottom:10px;
}
/*.event-summary-tgl{
	float:left;
	font-family: 'Open SansLight';
	font-size:40px;
	color:#ef7600;
	width:120px;
	padding-left:0;
}*/
.event-summary-tgl{
	float:left;
	font-family: 'Open SansLight';
	font-size:18px;
	color:#ef7600;
	width:70px;
	text-align:center;
	padding-left:0;
}
.event-summary-data{
	float:right;
	width: -moz-calc(100% - 70px);
    width: -webkit-calc(100% - 70px);
    width: -o-calc(100% - 70px);
    width: calc(100% - 70px); 

	border-left:5px solid #ef7600;
	border:1px solid red;
}
.event-summary-data ul{
	list-style:square;
	list-style-position:inside;
	padding:0px 0 0 10px;
}
.event-summary-data ul li{
	text-indent: -1em;
    padding-left: 1em;
}
.event-summary-data ul li a{
	color:#474747;
}
.event-kosong{
	color:#ef7600;
}

/****/
.knowledge-item{

	border-bottom:1px solid #f0f0f0;
	padding:10px 0;
}
.knowledge-top{
	display:inline-block;
	background:#96C;
	width:100%;
}
.knowledge-kategori{
	float:left;
	
	width: -moz-calc(100% - 180px);
    width: -webkit-calc(100% - 180px);
    width: -o-calc(100% - 180px);
    width: calc(100% - 180px); 

	background:#3CC;	
}
.knowledge-upload{
	float:right;
	width:180px;
	background:#F93;
	
	font-style:italic;
	font-size:12px;
	color:#acacac;

	background:url(../../public/media/images/icon-upload.png) left no-repeat;
	padding-left:20px;
	margin-top:10px;
}
.knowledge-upload span img{
	vertical-align:middle;
}
.knowledge-judul{
	font-weight:bold;
}
.knowledge-judul a{
	color:#474747;
}
/* .knowledge-isi{} */

/****/
/* .biodata-area{} */
img.foto{
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	
	margin:0 auto;
	
	width:200px;
	height:200px;
}
.biodata-foto{
	width:100%;
	text-align:center;
	border:1px solid red;
}
.biodata-nama{
	font-family: 'Open SansSemibold';
	font-size:18px;
	color:#1561ae/*00aeef*/;
	text-transform:uppercase;
}
.biodata-jabatan{
	font-family: 'Open SansRegular';
	color:#acacac;
	margin-bottom:10px;
}
.biodata-data table{
	width:100%;
	border-collapse:collapse;
	border-top:1px solid #e1e2e0;
}
.biodata-data table td{
	background:#f2f3f0;
	border-top:1px solid #fafaf9;
	border-bottom:1px solid #e1e2e0;
	padding:4px 7px;
}
.biodata-data table td:nth-child(1){
	width:180px;
}
.biodata-data table td:nth-child(3){
	color:#1561ae/*00aeef*/;
	font-weight:bold;
}

/****/
.spider-area{
	padding:10px 0;
}
/* .spider-data{} */
.spider-nilai{
	text-align:center; 
	line-height:90px;
	border-bottom:1px solid #f0f0f0;
	margin-bottom:18px;
	padding:10px 0;
	font-size:18px;
}
.spider-nilai span{
	vertical-align:middle;
	margin-left:20px;
	display:inline-block;
	text-align:center;
	line-height:90px;
	font-family: 'Open SansLight';
	font-size:48px;
	color:#1561ae/*00aeef*/;
	border:1px solid #1561ae/*00aeef*/;
	width:90px;
	height:90px;
	-webkit-border-radius: 45px;
	-moz-border-radius: 45px;
	border-radius: 45px;
}
.spider-ket{ padding:10px 0; text-align:justify;}

/****/
.narasumber-area{
	display:inline-block;
	width:100%;
}
.narasumber-item{
	float:left;
	
	width: -moz-calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: -o-calc(50% - 10px);
    width: calc(50% - 10px); 
	
	padding:10px 0;
}
.narasumber-item:nth-child(even){
	float:right;
	
	width: -moz-calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: -o-calc(50% - 10px);
    width: calc(50% - 10px); 
	
}

.narasumber-item-detil{
	float:left;
	width:100%;
	/*width: -moz-calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: -o-calc(50% - 10px);
    width: calc(50% - 10px); */
	
	padding:10px 0;
}

.narasumber-foto{
	float:left;
	width:140px;
}
.narasumber-foto img{
	
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	
	border:1px solid #f0f0f0;
	
	width:120px;
	height:120px;
}
.narasumber-data{
	float:right;
	
	width: -moz-calc(100% - 140px);
    width: -webkit-calc(100% - 140px);
    width: -o-calc(100% - 140px);
    width: calc(100% - 140px); 

}
.narasumber-nama{
	font-family: 'Open SansSemiBold';
	text-transform:uppercase;
	border-bottom:1px solid #f0f0f0;
}
.narasumber-nama a{
	color:#474747;
}
.narasumber-jabatan{ margin-bottom:10px;}
/* .narasumber-course{} */
.narasumber-course span{
	display:inline-block;
	width:26px;
	height:26px;
	
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	border-radius: 13px;
	
	text-align:center;
	line-height:26px;
	color:#FFF;
	
	background:#1561ae;
}
.narasumber-course-list{
	clear:both;
	display:inline-block;
	padding:10px 20px;
	border:1px solid #f0f0f0;
	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

/****/
.bersih{
	clear:both; margin-bottom:18px;
}


/****/
.aktivitas-item{
	display:inline-block;
	width:100%;
	padding:10px 0 0 0;
	border-bottom:2px solid #f0f0f0;
}
.aktivitas-item2{
	display:inline-block;
	width:100%;
	padding:10px 0;
	border-bottom:2px solid #f0f0f0;
}
.aktivitas-tgl{
	font-size:12px;
	color:#acacac;
}

.aktivitas-tgl img{
	margin-right:7px;
	vertical-align:middle;
}

.aktifitas-judul{
	/* padding:5px 0; */
	font-weight:bold;
	font-size:15px;
	/* border-bottom:1px dashed #f0f0f0; */
	/* margin-bottom:10px; */
	/* margin-bottom:10px; */
	text-transform:uppercase;
}

.aktivitas-data{
	
	display:inline-block;
	width:100%;
}
.aktivitas-kategori{
	float:left;
	width:140px;
	font-family: 'Open SansSemiBold';
	text-transform:uppercase;
	
	
}
.aktivitas-kategori a{
	color:#474747;
}
.aktivitas-nama{
	float:left;
	width: -moz-calc(100% - 140px);
    width: -webkit-calc(100% - 140px);
    width: -o-calc(100% - 140px);
    width: calc(100% - 140px); 

	font-family: 'Open SansSemiBold';
	padding:0 20px;
	text-align:justify;
	
	border-left:5px solid #5cb151;
}
.aktivitas-nama2{
	float:left;
	width: -moz-calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    width: -o-calc(100% - 200px);
    width: calc(100% - 200px); 

	font-family: 'Open SansSemiBold';
	padding:0 20px;
	text-align:justify;
	
	border-left:5px solid #5cb151;
}
.aktivitas-nama3{
	float:left;
	width: -moz-calc(100% - 140px);
    width: -webkit-calc(100% - 140px);
    width: -o-calc(100% - 140px);
    width: calc(100% - 140px); 

	font-family: 'Open SansSemiBold';
	padding:0 20px;
	text-align:justify;
	
	border-left:5px solid #ef00a3;
	border-left:5px solid #1561ae;
}

/** warna course **/
.aktivitas-nama4{
	float:left;
	width: -moz-calc(100% - 140px);
    width: -webkit-calc(100% - 140px);
    width: -o-calc(100% - 140px);
    width: calc(100% - 140px); 

	font-family: 'Open SansSemiBold';
	padding:0 20px;
	text-align:justify;
	
	border-left:5px solid #00aeef;
}
.aktivitas-nama5{
	float:left;
	width: -moz-calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    width: -o-calc(100% - 200px);
    width: calc(100% - 200px); 

	font-family: 'Open SansSemiBold';
	padding:0 20px;
	text-align:justify;
	
	border-left:5px solid #00aeef;
}

.aktivitas-nama a,
.aktivitas-nama2 a,
.aktivitas-nama3 a{
	color:#474747;
}
.aktivitas-grade{
	float:right;
	width:60px;
	line-height:24px;
	
	margin-left:10px;
	
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	font-family: 'Open SansLight';
	font-size:14px;
	color:#FFF;
	text-align:center;
	
	background:#00aeef;
}
.aktivitas-masuk{
	float:right;
	width:50px;
	margin-left:10px;
	
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;

	font-family: 'Open SansLight';
	font-size:20px;
	color:#FFF;
	text-align:center;
	line-height:50px;
	
	background:#00aeef;
}
.aktivitas-masuk a{
	color:#FFF;
	font-size:14px;
}

/****/

.aktivitas-tolak{
	float:right;
	width:50px;
	margin-left:10px;
	
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;

	font-family: 'Open SansLight';
	font-size:20px;
	color:#FFF;
	text-align:center;
	line-height:50px;
	
	background:#5cb151;
}
.aktivitas-tolak a{
	color:#FFF;
	font-size:14px;
}

/****/
.dashboard-item{
	float:left;
	width:33.3%;
	
	width: -moz-calc(33.3% - 20px);
    width: -webkit-calc(33.3% - 20px);
    width: -o-calc(33.3% - 20px);
    width: calc(33.3% - 20px); 
	
	text-align:center;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #dcdcdb;
	
	/* margin:0 10px 18px; */
	
	background:rgba(255,255,255,0.5);
}
.dashboard-item-kanan{
	float:left;
	width:100%;
	
	
	width: -moz-calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    width: -o-calc(100% - 20px);
    width: calc(100% - 20px); 
	
	text-align:center;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #dcdcdb;
	
	margin:0 10px 10px;
	
	background:rgba(255,255,255,0.5);
}
.dashboard-item:hover,
.dashboard-item-kanan:hover{
	
	background:#FFF;
}
.dashboard-item a{
	display:block;
	padding:20px;
}
.dashboard-item-kanan a{
	display:block;
	padding:10px 10px;
}
.dashboard-item-kanan a.current{
	background:#474747 !important;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
}
.dashboard-item-kanan a.current2{
	background:#474747 !important;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	color:#FFF;
	
}
.dashboard-item a:hover,
.dashboard-item-kanan a:hover{
	text-decoration:none;
}
.dashboard-icon{
	margin-bottom:-10px;
}
.dashboard-nama{
	font-family: 'Open SansSemiBold';
	font-size:16px;
	color:#b0b0b0;
	text-transform:uppercase;
	margin-top:-10px;
}
hr.style-two { border: 0; height: 1px; background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 174, 239, 0.75), rgba(0, 0, 0, 0)); }

/****/
.master-item-area{
	margin-top:10px;
}
.master-item{
	float:left;
	width:50%;
	border:1px solid #f0f0f0;
}

/****/
.knowledge2-item{
	display:inline-block;
	border-bottom:1px solid #f0f0f0;
	padding:10px 20px;
	width:100%;
	
	box-sizing:border-box;
}
.knowledge2-kategori{
	float:left;
	width:200px;
	
	box-sizing:border-box;
}
.knowledge2-nama{
	float:left;
	font-family: 'Open SansSemiBold';
	
	width: -moz-calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    width: -o-calc(100% - 200px);
    width: calc(100% - 200px); 
	
	border-left:5px solid #7e7e7e;
	padding-left:20px;
	
	box-sizing:border-box;

}
.knowledge2-nama a{
	color:#474747;
	text-decoration:none;
}
.knowledge2-nama span.tgl{
	font-family: 'Open SansRegular';
	color:#acacac;
	display:block;
}

/****/
.menu-kanan{
	padding:18px 0 0 0;
}
.menu-kanan ul{
	list-style-image:url(../../public/media/images/icon-detil.png);
	list-style-position:inside;
	list-style:none;
	padding:0 0;
}
.menu-kanan ul li{
	
	background:#eeefeb;
	border-bottom:1px solid #eeefeb;
	
}
.menu-kanan ul li a{
	display:block;
	line-height:10px;
	padding:10px 0 10px 10px ;
}
.menu-kanan ul li a:hover{
	text-decoration:none;
	
	/* fallback */ 
	/* background-color: #e0e0e0; background: url(images/linear_bg_2.png); background-repeat: repeat-x;  */
	
	/* Safari 4-5, Chrome 1-9 */ 
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#e0e0e0), to(#f0f0f0)); 
	
	/* Safari 5.1, Chrome 10+ */ 
	background: -webkit-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* Firefox 3.6+ */ 
	background: -moz-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* IE 10 */ 
	background: -ms-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* Opera 11.10+ */ 
	background: -o-linear-gradient(top, #e0e0e0, #f0f0f0);
}
.menu-kanan ul li a img{
	vertical-align:middle;
}
.menu-kanan ul li a span{
	display:inline-block;
	background:url(../../public/media/images/icon-detil.png);
	width:20px;
	height:20px;
	vertical-align:middle;
	margin-right:7px;
}
.menu-kanan ul li a.current{
	background:#FFF;
}

/****/
.aksi-area{
	color:#333333;
	font-family: 'Open SansSemibold';
	font-size:12px;
	text-transform:uppercase;
	
	height:40px;
	line-height:34px;

	background:#f0f0ee;
	border-top:6px solid #1561ae;
	
	padding:6px 10px;

}
.aksi-area span{
	display:inline-block;
	margin-right:0px;
}
.aksi-area span img{
	vertical-align:middle;
}

.aksi-area span a{
	padding:0 10px;
	display:inline-block;
	/* fallback */ 
	/*background-color: #f0f0f0; background: url(images/linear_bg_2.png); background-repeat: repeat-x; 
	
	/* Safari 4-5, Chrome 1-9 *
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f0f0f0), to(#e0e0e0)); 
	
	/* Safari 5.1, Chrome 10+ * 
	background: -webkit-linear-gradient(top, #f0f0f0, #e0e0e0); 
	
	/* Firefox 3.6+ *
	background: -moz-linear-gradient(top, #f0f0f0, #e0e0e0); 
	
	/* IE 10 *
	background: -ms-linear-gradient(top, #f0f0f0, #e0e0e0); 
	
	/* Opera 11.10+ *
	background: -o-linear-gradient(top, #f0f0f0, #e0e0e0);*/
}
.aksi-area span a:hover{
	text-decoration:none;
	
	/* fallback */ 
	/* background-color: #e0e0e0; background: url(images/linear_bg_2.png); background-repeat: repeat-x;  */
	
	/* Safari 4-5, Chrome 1-9 */ 
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#e0e0e0), to(#f0f0f0)); 
	
	/* Safari 5.1, Chrome 10+ */ 
	background: -webkit-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* Firefox 3.6+ */ 
	background: -moz-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* IE 10 */ 
	background: -ms-linear-gradient(top, #e0e0e0, #f0f0f0); 
	
	/* Opera 11.10+ */ 
	background: -o-linear-gradient(top, #e0e0e0, #f0f0f0);
}
.aksi-area span.total-grade{
	float:right;
	padding-right:10px;
}
.aksi-area span.total-grade input{
	height:24px !important;
}

/****/
.kalender-ket{
	margin-top:3px;
	padding-top:10px;
	/* border-top:1px solid #f0f0f0; */
}
.kalender-ket ul{
	list-style:none;
	list-style-position:inside;
	padding:0 0;
}
.kalender-ket ul li span{
	width:11px;
	height:11px;
	display:inline-block;
	margin-right:10px;
}
.kalender-ket ul li:nth-child(1) span{	background:#ffd843; }
.kalender-ket ul li:nth-child(2) span{	background:#0092c2; }
.kalender-ket ul li:nth-child(3) span{	background:#ff922f; }
.kalender-ket ul li:nth-child(4) span{	background:#d5d6d3; }
.kalender-ket ul li:nth-child(5) span{	background:#5ad9e4; }

.event1{		background:#ffd843 !important;}
.event2{		background:#0092c2 !important;}
.event3{		background:#ff922f !important;}
.event4{		background:#d5d6d3 !important;}
.event5{		background:#5ad9e4 !important;}

/** TAMBAHAN DATATABLE **/
.dataTables_length{
	float:left;
	width:50%;
	height:40px;
	line-height:40px;
	
	padding-left:10px;
	
}
.dataTables_filter{
	float:right;
	width:50%;
	height:40px;
	line-height:30px;
	
	padding-right:10px;
	padding-top:6px;
	text-align:right;
	
}
.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate{
	background:#e0f5fd;
	
	border-top:1px solid #e1e1e1;
	border-bottom:2px solid #d5d5d5;
}

.dataTables_length label,
.dataTables_filter label{
	font-size:12px;
}

.dataTables_info{
	float:left;
	width:50%;
	height:40px;
	line-height:36px;
	padding-left:10px;
	font-size:12px;
}
.dataTables_paginate{
	float:right;
	width:50% !important;
	height:40px;
	line-height:36px;
	padding-right:10px;
	
	text-align:right;
}
a.paginate_button,
a.first,
a.last,
a.previous,
a.next,
a.fg-button {
	background:#00aeef;
	color:#FFF;

	
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	
	padding:2px 10px;
	margin:0 1px;
	font-size:12px;
}
a:hover.paginate_button {
	color:#474747;
	text-decoration:none;
}
a.paginate_button.current {
	background:#1561ae;
}
a.paginate_button.disabled,
a.ui-state-disabled {
	background:#a1a1a1;
}

table#example{
	clear:both;
}
/* span.dtr-data{} */

/** TAMBAHAN BOOTSTRAP **/
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

/****/
a.dropdown-toggle span{
	display:inline-block;
	width:40px;
	height:40px;
	line-height:40px;
	vertical-align:middle;
	background: url(../../public/media/images/icon-user.png);
	margin-top:-10px;
	margin-bottom:-10px;
}
img.icon-user{
	margin-top:-10px;
	margin-bottom:-10px;
}
img.foto-user{
	width:40px;
	height:40px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	
}

/****/
.agenda-area{
	border-bottom:1px solid #f0f0f0;
	padding-bottom:10px;
	
}
.agenda-item{
	border-bottom:1px solid #f0f0f0;
	display:inline-block;
	width:100%;
	box-sizing:border-box;
	
	padding:10px 20px;
}
.agenda-waktu{
	float:left;
	width:25%;
	border:1px solid red;
	
	box-sizing:border-box;
	padding:10px;
}
.agenda-tgl{
	text-align:right;
}
.agenda-jam{
	text-align:right;
}
.agenda-data{
	float:right;
	width:75%;
	border-left:5px solid #ef7600;
	
	box-sizing:border-box;
	padding:10px;
}
.agenda-nama{
	font-family: 'Open SansSemibold';
	font-size:16px;
	color:#00aeef;
	text-transform:uppercase;
}
.agenda-keterangan{
	text-align:justify;
}

/****/
.notifikasi-area{
	border:1px solid red;
	width:100% !important;
	height:100% !important;
	height:300px;
	
}
.notifikasi-item{
	border-bottom:1px solid #f0f0f0;
	padding:10px 0;
}
.notifikasi-waktu{
	color:#ef7600;
	text-transform:uppercase;
	font-size:14px;
	font-style:italic;
}
.notifikasi-nama{
	text-transform:uppercase;
}

/****/
.modal-content iframe{
	border:none;
}

@media (max-width:767px) {
	.logo{
		background:url(../../public/media/images/logo4.png) no-repeat;
		background-size:100%;
		width:230px;
	}

	.narasumber-item,
	.narasumber-item:nth-child(even){
		float:left;
		
		width:100%;
		
		padding:10px 0;
	}
	
	.biodata-nama{
		text-align:center;
	}
	.biodata-jabatan{
		text-align:center;
	}
	
	/****/
	.dashboard-item{
		float:left;
		width:50%;
		
		width: -moz-calc(50% - 20px);
		width: -webkit-calc(50% - 20px);
		width: -o-calc(50% - 20px);
		width: calc(50% - 20px); 
		
		min-height:150px;
		
		text-align:center;
		
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		
		border:1px solid #dcdcdb;
		
		margin:0 10px 18px;
		
		background:rgba(255,255,255,0.5);
	}
	.dashboard-nama{
		font-family: 'Open SansSemiBold';
		font-size:14px;
		color:#b0b0b0;
		text-transform:uppercase;
		margin-top:-10px;
	}



}

/****/
.data-table{	display: table; width:100%;}
.data-row{		display: table-row;}
.data-cell{		display: table-cell;
				padding:4px 7px;
				border:1px solid #f0f0f0;
}

/****/
.jadwal-area{
	padding-bottom:10px;
}

.jadwal-list{
	padding:0 0 10px 0;
	border-bottom:1px solid #f0f0f0;
}
.jadwal-list-wrapper{
	background:#C36;
	position:relative;
	display:inline-block;
	
	width:100%;
	height:100%;
}
.jadwal-waktu{
	color:#00aeef;
	font-size:12px;
}
.jadwal-nama{
	font-family: 'Open SansSemiBold';
	/* text-align:justify; */
}
/* .jadwal-section{} */
.jadwal-section ul{
	padding: 0px 0 0px 0px;
	border:1px solid #f0f0f0;
	list-style:none;
	margin-top:5px;
}
.jadwal-section ul li {
    list-style-type:square;
    list-style-position: inside;
    text-indent		: -1em;
    padding-left	: 1em;
	padding-left	:25px;
	padding-top		:7px;
	padding-bottom	:7px;
	
	list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQIW2NkYGD4D8RwwEi6AACaVAQBULo4sgAAAABJRU5ErkJggg==");
	border-bottom:1px solid #f0f0f0;
	
	display:inline-block;
	width:100%;
	height:100%;
	min-height:100%;
	

	
	/****/
	
	position: relative;
	display: table; 
	text-align: center;
	width: 615px;

	border: 1px solid #344491;
	background: #6673af;
	font-family: arial, sans-serif;
	font-size: 13px;
	color: #fff;

}
.jadwal-section ul li div{
	display: table-cell; 
	vertical-align: middle; 
	text-align: center; 
}


.jadwal-section ul li:before {
	
    content: "• ";
    color: #474747; /* or whatever color you prefer */

}
.jadwal-section ul li.materi-kosong:before {
	
    content: "• ";
    color:#ef7600;

}
.jadwal-section ul li a{
	font-size:13px;
	text-transform:uppercase;
}
.jadwal-section ul li.materi-kosong{
	font-size:13px;
	color:#ef7600;
}


.jadwal-section ul li div.judul{
	
	float:left;
	
	width: -moz-calc(100% - 190px);
    width: -webkit-calc(100% - 190px);
    width: -o-calc(100% - 190px);
    width: calc(100% - 190px); 
	
	
	padding-right:10px;
	background:#99C;

	
}
.jadwal-section ul li div.tanggal{
	background:#0C6;
	
	
	width:190px;
	
	font-size:12px;
	text-align:right;
	padding-right:10px;
	
	border-left:1px solid #f0f0f0;
}
	
	/*width:190px;
	
	display: marker; 
	vertical-align:middle;
	
	color:#b0b0b0;
	
	
	background:#ff0;
	
	position:absolute;
	right:0;
	bottom:0;
	top:0;
	/
	
	
}

.jadwal-section ul li div.aktivitas{
	
	float:left;
	
	width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
    width: -o-calc(100% - 90px);
    width: calc(100% - 90px); 
	
	
	padding-right:10px;
	background:#99C;

	
}
.jadwal-section ul li div.nilai{
	background:#0C6;
	
	
	width:90px;
	
	text-align:right;
	padding-right:10px;
	
	border-left:1px solid #f0f0f0;
	
	/*width:190px;
	
	display: marker; 
	vertical-align:middle;
	
	color:#b0b0b0;
	
	
	background:#ff0;
	
	position:absolute;
	right:0;
	bottom:0;
	top:0;
	/
	
	
}

/** current **/
.jadwal-section ul li.current{
	background:#00aeef;
}
.jadwal-section ul li.current a{
	color:#FFF;
}
.jadwal-section ul li.current span.tanggal{
	color:#ace8fe;
}

/****/
.btn.btn-daftar,
.btn.btn-daftar:focus{
	background:#ef7600;
	color:#fff;
}
.btn.btn-daftar:active{
	background:#bf5e00;
	color:#fff;
}
.btn.btn-daftar:hover{
	background:#bf5e00;
	color:#fff;
}
.btn.btn-daftar:disabled{
	background:#ef7600;
	color:#fff;
}
.kuliah-mendaftar{
	background:#ef7600;
	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border: none;
	color: white;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	height: 72px;
	
	/* -webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px; */
	
	/* margin-top:18px; */
}
.kuliah-mendaftar:hover{
	background:#bf5e00;
	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border: none;
	color: white;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	height: 72px;
	
	/* -webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px; */
	
	/* margin-top:18px; */
}
.kuliah-mendaftar a{
	display:block;
	text-align:center;
	color:#FFF;
	padding:18px 0;
	
	font-family: 'Open SansSemiBold';
	font-size:18px;
	text-transform:uppercase;
}
.kuliah-terdaftar{
	background:#4fbe47;
	
	text-align:center;
	color:#FFF;
	padding:18px 4px;
	
	font-family: 'Open SansSemiBold';
	font-size:16px;
	text-transform:uppercase;
	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	
	
}

.kuliah-tutup{
	background:#EA5357;
	
	text-align:center;
	color:#FFF;
	padding:18px 0;
	
	font-family: 'Open SansSemiBold';
	font-size:16px;
	text-transform:uppercase;
	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	
	
}

/****/
.sidebar-anak{
	border:1px solid #f0f0f0;
	padding:18px 20px;
	margin:18px 0;
}

/****/
.easyui-panel{
	padding:5px 0 20px;
}

/****/
.komentar-area{
	border:1px solid #f0f0f0;
	padding:5px 20px 0;
	
}
.komentar-list{
	background:#f7fafb;
	display:inline-block;
	width:100%;
	border-bottom:1px solid #eeeeee;
	padding:10px 10px;
}
.komentar-list:nth-child(odd){
	background:#fafafa;
}
.komentar-foto{
	background:#FC9;
	float:left;
	width:40px;
	margin-right:10px;
}
.komentar-foto img{
	width:40px;
	height:40px;
}
.komentar-data{
	width: -moz-calc(100% - 50px);
    width: -webkit-calc(100% - 50px);
    width: -o-calc(100% - 50px);
    width: calc(100% - 50px); 
	background:#FC0;
	float:left;

}
.komentar-subjek{
	display:inline-block;
	width:100%;
}
	.komentar-nama{
		background:#0FC;
		float:left;
		color:#00aeef;
		font-weight:bold;
	}
	.komentar-tgl{
		background:#FCF;
		float:right;
		color:#d0d0d0;
	}
.komentar-isi{
	background:#9C3;
	text-align:justify;
}

/****/
.ulasan-area{
	padding:10px 0;
}
.ulasan-list{
	border-bottom:1px solid #f0f0f0;
	display:inline-block;
	width:100%;
	padding:10px 0;
}
.ulasan-pic{
	float:left;
	width:60px;
}
.ulasan-pic img{
	
	width:40px;
	height:40px;
	
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}
.ulasan-data{
	float:right;
	
	width: -moz-calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -o-calc(100% - 60px);
    width: calc(100% - 60px); 

}
.ulasan-rate{
	margin-bottom:5px;
	font-size:12px;
}
.ulasan-isi{
	color:#8d8d9c;
	font-style:italic;
	
	display:inline-block;
	width:100%;
}
.ulasan-isi span{
	font-weight:bold;
	color:#474747;
}
.ulasan-peserta{
	margin-bottom:5px;
	font-size:14px;
	text-transform:uppercase;
	border-bottom:1px solid #f0f0f0;
	padding-bottom:10px;
	margin-bottom:10px;
}
.ulasan-terdaftar{
	color:#8d8d9c;
	font-style:italic;
}
.ulasan-terdaftar span:nth-child(1){
	background:#65a812;
	color:#FFF;
	height:30px;
	line-height:30px;
	padding:0 15px;
	display:inline-block;
	
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}
.ulasan-terdaftar span:nth-child(2){
	background:#ef7600;
	color:#FFF;
	height:30px;
	line-height:30px;
	padding:0 15px;
	display:inline-block;
	
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}



/****/
.lampiran-area{
	border:1px solid #f0f0f0;
	border-width:1px 1px 1px 1px;
	margin-bottom:18px;
}
.lampiran-area ul{
	list-style:none;
	padding:0 20px 0px 20px;
	list-style-position:inside;
}
.lampiran-area ul li{
	background: url(../../public/media/images/icon-attachment.png) left no-repeat;
	border-bottom:1px solid #f0f0f0;
	padding:10px 0 10px 23px;
}
.lampiran-area ul li:last-child{
	background: url(../../public/media/images/icon-attachment.png) left no-repeat;
	border-bottom: none;
	padding:10px 0 0px 23px;
}
.lampiran-area table{
	border-collapse:collapse;
	width:100%;
	border:1px solid #f0f0f0;
}
.lampiran-area table th{
	border:1px solid #f0f0f0;
	padding:4px 7px;
	background:#1561ae;
	color:#FFF;
}
.lampiran-area table td{
	border:1px solid #f0f0f0;
	padding:4px 7px;
}
.kosong{
	color:#ef7600;
	background:url(../../public/media/images/icon-seru.png) left no-repeat;
	padding:10px 0 10px 23px;
	margin-left:20px;
}

/****/
.lampiran-video-area{
	border:1px solid #f0f0f0;
	border-width:1px 1px 1px 1px;
	margin-bottom:18px;
}
.lampiran-video-area ul{
	list-style:none;
	padding:0 20px 0px 20px;
	list-style-position:inside;
}
.lampiran-video-area ul li{
	background: url(../../public/media/images/icon-video.png) left no-repeat;
	border-bottom:1px solid #f0f0f0;
	padding:5px 0 5px 23px;
}
.lampiran-video-area ul li:last-child{
	background: url(../../public/media/images/icon-video.png) left no-repeat;
	border-bottom: none;
	padding:5px 0 0px 23px;
}

.lampiran-video-area ul li a{
	display:block;
	padding:5px;
}
.lampiran-video-area ul li.current a{
	color:#FFF;
	font-weight:bold;
	background:#00aeef;
}



/****/
.respon-klik{
	background:#ef7600 url(../../public/media/images/icon-respon.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.respon-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;
	cursor:pointer;

	
}
.respon-info{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #ef7600;
	
	display:inline-block;
	padding: 5px 10px 5px;
	
	color:#ef7600;
	font-size:14px;
	margin-top:10px;
}

/****/
.publish-info{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #00aeef;
	
	display:inline-block;
	padding: 5px 10px 5px;
	
	color:#00aeef;
	font-size:14px;
	margin-top:10px;
}

/****/
.rating-klik{
	background:#00aeef url(../../public/media/images/icon-respon.png) left no-repeat;
	padding-left:23px;
	/* display:inline-block; */
	float:right;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.rating-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}
.rating-info{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #00aeef;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#00aeef;
	font-size:14px;
	margin-top:10px;
}

/****/
.masuk-klik{
	background:#1561ae url(../../public/media/images/icon-masuk2.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.masuk-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}
.masuk-info{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #1561ae;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#1561ae;
	font-size:14px;
	margin-top:10px;
}

/****/
.kosong-info{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #b0b0b0;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#b0b0b0;
	font-size:14px;
	margin-top:10px;
}

.kosong-info-orange{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #ef7600;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#ef7600;
	font-size:14px;
	margin-top:10px;
	
	width:100%;
}
.kosong-info-blue{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #00aeef;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#00aeef;
	font-size:14px;
	margin-top:10px;
	
	width:100%;
}
.kosong-info-grey{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	border:1px solid #b0b0b0;
	
	/* display:inline-block; */
	float:right;
	padding: 5px 10px 5px;
	
	color:#b0b0b0;
	font-size:14px;
	margin-top:10px;
	
	width:100%;
}

/****/
.konfirm-area{
	border:1px solid red;
	display:inline-block;
	width:100%;
	text-align:right;
}
/****/
.ya-klik{
	background:#00aeef url(../../public/media/images/icon-masuk2.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.ya-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}

/****/
.tidak-klik{
	background:#ef7600 url(../../public/media/images/icon-abaikan.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.tidak-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}

/****/
.view-detil2{
	background:#b0b0b0 url(../../public/media/images/icon-view.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
}
.view-detil2 a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}

/****/
.lihat-semua-klik{
	background:#b0b0b0 url(../../public/media/images/icon-view.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	width:100%;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
	margin-bottom:10px;
}
.lihat-semua-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}

/****/
.lihat-semua-event-klik{
	background:#ef7600 url(../../public/media/images/icon-view.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
	margin-bottom:10px;
	margin-top:-10px;
	margin-left:20px;
}
.lihat-semua-event-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;	
}

/****/
.share-klik{
	background:#b0b0b0 url(../../public/media/images/icon-share.png) left no-repeat;
	padding-left:23px;
	display:inline-block;
	width:100%;
	
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	margin-top:10px;
	margin-bottom:10px;
}
.share-klik a{
	color:#FFF;
	font-size:14px;
	padding: 5px 10px 5px;
	display:block;

	
}

/****/
/* #kontendata{} */
#kontendata ul{
	list-style:none;
	padding:0 0;
}
#kontendata ul li{
	border-bottom:1px solid #f0f0f0;
	padding:10px 0;
}

/****/
.peringatan{
	color:red;
	border:1px solid red;
	padding:10px 20px;
	margin:10px 0;
}

/**** 2.09.2015 ****/
.countdown-area{
	float:right;
}

/****/
.soal-area{
	border:1px solid blue;
	max-width:calc(100% - 0px);
	max-height: 550px;
	overflow-y:auto;
	
	width:100%;
}
.soal-area ul{
	list-style:decimal;
	padding:0 0 0 10px;
	list-style-position:inside;
}
.soal-area ul li{
	list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
	font-weight:bold;
	
	border-bottom:1px solid #f0f0f0;
	padding:10px 0;

}
.soal-area ul li ul.jawaban{
	list-style: lower-alpha;
	list-style:none;
	padding:0 1.5em;
	list-style-position:inside;
	padding:0 0 0 4px;
	margin-top:10px;
}
.soal-area ul li ul.jawaban li{
	list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
	font-weight:normal;
	
	border-bottom:0px solid #f0f0f0;
	border-left:4px solid #00aeef;
	padding:0 0 0 23px;

}
.soal-area ul li ul.jawaban li input[type = radio]{
	margin-right:10px;
}
.soal-area ul li div.jawaban {
	padding-left:23px;
	border-left:4px solid #00aeef;
	margin-left:4px;
	
	
}
.soal-area ul li div.jawaban textarea{
	border:1px solid #f0f0f0;
	width:100%;
	padding:10px;
	
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-weight:normal;
}


/****/
.jawab-area{
	height:450px;
	width:100%;
	padding:0 0 30px 0;
	overflow-y:auto;
	border:1px solid red;
}
.jawab-area table{
	border-collapse:collapse;
	width:100%;
	
}
.jawab-area table td{
	border-collapse:collapse;
	border:1px solid #f0f0f0;
	text-align:center;
	padding:4px 0px !important;
	height:20px;
	line-height:normal;
	
}

/****/
.progress-1 .bar{	background:#00aeef !important;}
.progress-2 .bar{	background:#e100ef !important;}
.progress-3 .bar{	background:#00dbef !important;}
.progress-4 .bar{	background:#11ef00 !important;}
.progress-5 .bar{	background:#e7ef00 !important;}

.progress-6 .bar{	background:#ef7000 !important;}
.progress-7 .bar{	background:#00efe7 !important;}
.progress-8 .bar{	background:#ef00ba !important;}
.progress-9 .bar{	background:#a9ef00 !important;}
.progress-10 .bar{	background:#5400ef !important;}

/****/
table input,
table input.easyui-validatebox,
table input.easyui-datebox,
table select,
input.combo-text{
	font-family: "Open SansRegular";
	font-size:14px !important;
}
span.tagit-label{
	font-size:14px !important;
	color:red !important;
}

/****/
div.panel.combo-p{
	padding:0 0 !important;
}
div.combo-panel.panel-body.panel-body-noheader{
	width:100% !important;
	margin:0 0 !important;
}

/****/
.agenda-saya-area{
	border-left:5px solid #eeeeee;
	padding-left:20px;
}

.agenda-saya-list{
	border-bottom:1px solid #f0f0f0;
	display:inline-block;
	padding:10px 0;
	width:100%;
}
.agenda-saya-tgl{
	font-family: "Open SansRegular";
	font-size:14px !important;
	font-style:italic;
	color:#ef7600;
}
.agenda-saya-nama{
	font-family: "Open SansRegular";
	font-size:17.5px;
	text-transform:uppercase;
}

/****/
#parameter-tambahan{
	position:relative; background:#337ab7; color:#FFF; padding:5px 10px;
	font-size:11px;
}
#parameter-tambahan select{
	font-size:8x;
	color:#000; 
}

/****/
.akses-area{
	display:inline-block;
	width:100%;
	background:#0FF;
	border:1px solid black;
}
.akses-list{
	/*display:inline-block;
	width:100%;
	background:#0FF;
	border:1px solid black;*/
	padding:5px 10px;
	width:100%;
	
	width: -moz-calc(50% - 10px);
    width: -webkit-calc(50% - 10px);
    width: -o-calc(50% - 10px);
    width: calc(50% - 10px); 

	box-sizing:border-box;
	float:left;
	
	border:1px solid #f0f0f0;
	background:#f9f8f7;
	margin-bottom:5px;
}
.akses-list:nth-child(even){
	float:right;
}
.akses-parent{
	background:#963;
	border-bottom:1px solid #f0f0f0;
	padding-bottom:7px;
	margin-bottom:7px;
}
.akses-child{
	background:#9C3;
	display:inline-block;
	width:100%;
	
	border-bottom:1px solid #f0f0f0;
	padding-bottom:7px;
}
.akses-nama:before{
	content:"- ";
}

.akses-nama{
	float:left;
	width:300px;
	border:1px solid red;
	padding:10px 0;
	padding-left:10px;
}
.akses-tipe{
	float:left;
	border:1px solid green;
	
	padding:10px 0;
}

/****/
.statistik-area{
	border:1px solid red;
	min-height:300px;
}
.statistik-bar{
	border:1px solid #f0f0f0;
	padding:10px;
}
.statistik-pie{
	border:1px solid #f0f0f0;
	text-align:center;
	padding:10px;
}

/****/
.pencarian-area-home{
	border:0px solid #C39;
	float:right;
	width:510px;
	text-align:right;
}
.pencarian-area-home .easyui-combotree{
	border:1px solid red !important;
	
}
.pencarian-area-home span.combo{
	border:1px solid #0db7f7 !important;
	background:#00a3e0 !important;
	
	background:rgba(0,0,0,0.3);
	border : solid 1px rgba(255,255,255,0.5);
	
	height:30px !important;
	
	width:250px !important;
	
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
/*.pencarian-area-home span.combo-arrow{
	border:0px solid red !important;
	height:30px !important;
	background-color:transparent;
	color:#dfdfdf !important;
}*/
.pencarian-area-home span.combo-arrow {
    /* background: transparent url(../../public/media/images/combo_arrow.png) no-repeat scroll center center; */
	background: url(../../public/media/images/icon-combo.png) center center no-repeat !important;
	width:30px !important;
	height:30px !important;
}
.pencarian-area-home .combo-text.validatebox-text{
	border:1px solid red !important;
	border:none !important;
	width:220px !important;
	background-color:transparent;
}
.pencarian-area-home .easyui-validatebox{
	border:1px solid #0db7f7 !important;
	background:#00a3e0 !important;
	
	background:rgba(0,0,0,0.3);
	border : solid 1px rgba(255,255,255,0.5) !important;
	
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	
	height:30px !important;
	width:240px !important;
}
.pencarian-area-home .tombol-cari{
	background: url(../../public/media/images/icon-cari.png) center center no-repeat !important;
	border:none !important;
	margin-left:-30px;
}

/****/
.pencarian-area{
	border:1px solid red;
	background:#f0f0f0;
	padding:5px 10px;
}
.pencarian-area input{
	vertical-align:bottom;
}
.pencarian-area span.combo{
	height:30px !important;
	
	border:1px solid #e6e6e6 !important;
	
	-webkit-border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	border-radius: 3px !important;
	
}
.pencarian-area span.combo input.combo-text{
	height:30px !important;
}
.pencarian-area span.combo span{
	border:2px solid red !important;
	height:30px !important;
	padding-top:10px;
}
.pencarian-area input.easyui-combotree{
	margin-bottom:4px !important;
	
	display:inline-block;
	
	height:30px !important;
}
.pencarian-area input.easyui-validatebox{
	display:inline-block;
	
	-webkit-border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	border-radius: 3px !important;
	
	border:1px solid #e6e6e6 !important;
	padding-top:0px !important;
	padding-bottom:0px !important;
	
	margin-top:2px !important;
	height:30px !important;
}

/****/
.sisa-waktu{
	border:0px solid red; 
	margin-top:0; 
	height:100%; 
	width:auto; 
	padding:20px; 
	background:#00aeef; 
	color:#FFF;
	text-transform:uppercase;
}
.sisa-waktu span{
	border-right:1px solid #FFF;
	padding-right:20px;
}
.sisa-waktu label{
	font-size:24px;
	vertical-align:middle;
	margin-left:20px;
}

/****/
.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0.0; }
}

/****/
.btn-taut{
	float:right; margin-right:4px;
	margin-top:10px;
	margin-bottom:10px !important;
}
.btn-taut a{
	color:#FFF;
}

/****/
.hastag-area{
	background:#f0f0f0;
	padding:10px;
	color:#b8b8b8;
	font-size:13px;
	border-top:1px solid #e0e0e0;
	border-bottom:1px solid #e0e0e0;
}

/****/
.persetujuan{
	border:1px solid red;
	background:#f8f8f8;
	padding:7px 10px;
}
/* .persetjuan input[type = checkbox]{} */
.persetujuan label{
	font-size:12px;
	color:#ef7600;
	margin-left:5px;
}

/****/
.diterima-ditolak{
	float:right;
	background:#9C0;
	margin-top:10px;
}
.diterima-ditolak span{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	
	height:30px !important;
	line-height:15px !important;
}
.diterima-ditolak span a{
	background:#0CF;
	display:block;
	color:#FFF;
}

/****/
.kategori-diklat{
	background:#f9d7b3;
	padding-bottom:5px;
	margin-bottom:18px;
}
.kategori-diklat .kategori{
	border-bottom:1px solid #f9f8f7;
	padding:10px;
}
.kategori-diklat .area{
	margin:10px;
	padding:18px 20px 8px;
	border:1px solid #FFF;
}
.kategori-diklat .area .list{
	border-bottom:1px solid #f9f8f7;
	padding-bottom:8px;
	margin-bottom:10px;
}
/* .kategori-diklat .nama{} */
.kategori-diklat .nama a{
	color:#454545;
	font-weight:bold;
}
.kategori-diklat .nama a:hover{
	color:#333;
	font-weight:bold;
}


/****/
.knowledge-banner{
	float:left;
	width:100px;
	margin-right:20px;
}
.knowledge-konten{
	float:right;
	
	width: -moz-calc(100% - 120px);
    width: -webkit-calc(100% - 120px);
    width: -o-calc(100% - 120px);
    width: calc(100% - 120px); 

}

/****/
.area-share{
	float:right; 
	margin-top:0px; 
	margin-right:20px;
	
	border:1px solid red;
	/* display:inline-block; */
	height:20px !important;
	height:33px;
	line-height:33px;
	
	background:#f4f4f4;
	padding-top:1px;
	
	
}
/* .area-twitter{} */
div.pluginButtonContainer{
	line-height:45px;
}
.area-share i{
	color:#FFF;
}
/* .area-share a.twitter-share-button{
	display:inline-block;
	vertical-align:middle;
	margin-top:10px;
} */

div.pluginConnectButton{
	border:1px solid red !important;
}
div.btn-o{
	margin-bottom:-10px !important;
	display:inline-block;
}
.breadcrumb{
	text-transform: capitalize;
	margin: 1px 0 !important;
}
.breadcrumb-active:hover{
	text-decoration: underline;
}
.breadcrumb-slash{
	padding:0 8px 0 10px;
	color: #ccc;
	font-size: 15px;
}
.breadcrumb-passive{
	color: #777;
}
.soal{
	margin: 0 0 10px 0;
}
.jawaban-soal{
	margin-left: 20px;
	padding-left: 10px;
}

.btn-link {
	text-decoration: none !important;
	color: black !important;
	border: none !important;
}

.btn-link-special {
	text-decoration: none !important;
	color: #0d6efd !important;
	border: none !important;
}

.btn-link:hover {
	text-decoration: none !important;
	border: none !important;
	color: #0d6efd !important;
}

.navbar-nav .nav-link{
	font-size: 14px !important;
	color: #a1a5b7;
	border-bottom: 2px solid transparent;
}
.navbar-nav .nav-link:hover{
	color: #a1a5b7;
	border-bottom: 2px solid #009ef7;
}
.navbar-nav .nav-link.active{
	color: #009ef7 !important;
	border-bottom: 2px solid #009ef7;
}

/* -------------------------------------------------------------------------
  VERTICAL STEPPERS
-------------------------------------------------------------------------- */

.wrapper-stepper {
	margin-left: 20px;
}

@media (max-width:767px) {
	.wrapper-stepper {
		margin-bottom: 20px;
	}	
}

/* Steps */
.step {
  position: relative;
  min-height: 1em;
  color: gray;
}
.step + .step {
  margin-top: 2em
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-left: 2em;
  padding-left: 1em;
}
.step.step-active {
  color: #4285f4
}
.step.step-active .circle {
  background-color: #4285f4;
}

/* Circle */
.circle {
  background: gray;
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle:after {
  content: ' ';
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: -1;
}
.step:last-child .circle:after {
  display: none
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
  font-weight: bold;
}
.caption {
  font-size: 0.8em;
}

.tombol-gambar {
  all: unset;
}

.tombol-gambar:active {
  all: unset;
}

.tombol-gambar:focus {
  all: unset;
}

.dangerouslyhtml > p {
	margin-bottom: 0px !important;
}

.cursor-pointer {
	cursor: pointer;
}

.lms-breadcrumb {
	padding: 0.5rem 1.5rem 0;
	color: #adb5bd;
}

/* LMS - MAIN COURSE PAGE */
.card-icon {
	min-height: 144px;
	cursor: pointer;
	/* color: #fff !important; */
}

.bg-panel {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.bg-panel:hover {
  background: rgba(0, 0, 0, 0.7);
}

.title-category{
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1rem;
  text-align: right;
  opacity: 1;

  line-height: 1.1;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 1px .8px #212529;
  color: #fff;
}
.card-icon:hover .title-category {
  font-size: 1.64rem;
  /* font-weight: 600; */
  text-shadow: 1px .8px #212529;
}
.card-body {
  font-size: .9rem;
}

/*.card-icon.id0 {
	background:#488a99 !important;
}
.card-icon.id1 {
	background:#ac3e31 !important;
}
.card-icon.id2 {
	background:#dbae58 !important;
}
.card-icon.id3 {
	background:#484848 !important;
}
.card-icon.id4{
	background:#ea6a47 !important;
}
.card-icon.id5{
	background:#488a99 !important;
}
.card-icon.id6{
	background:#68d388 !important;
}
.card-icon.id7{
	background:#ac3e31 !important;
}
.card-icon.id8{
	background:#484848 !important;
}
.card-icon.id9{
	background:#ea6a47 !important;
} */
/* --LMS - MAIN COURSE PAGE-- */

/* LMS - CATEGORY COURSE PAGE */
.title-card {
  --lh: 1.4rem;
  line-height: var(--lh);
  font-weight: 500;
  font-size: 1.25rem;
  color: #212529;
  /* width: 400px; */
}

.module {
  /* width: 250px; */
  margin: 0;
  overflow: hidden;
}
.module p {
  margin: 0;
  font-size: 1.1rem;
}

.faded {
  position: relative;
  height: 2.4em;
}
.faded:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
}
/* --LMS - CATEGORY COURSE PAGE-- */

/* LMS - OVERVIEW COURSE PAGE */
.item-nav {
	display: flex;
	align-items: center;
	padding-top: 3px;
	padding-bottom: 2px;
	white-space: nowrap;
	color: #adb5bd;
	cursor: pointer;
}

.item-nav:hover {
	color: #6c757d;
	border-bottom: 2px solid #6c757d;
}

.item-nav.active {
	color: #0d6efd;
	border-bottom: 2px solid #0d6efd;
}
/* --LMS - OVERVIEW COURSE PAGE-- */

/* LMS - LEARNING COURSE PAGE */
.left-menu {
	width: 100%;
}

.left-menu.cut {
	width: 76%;
}

.right-menu.hidden {
	display: none;
}

.right-menu {
  z-index: 2;
	float: right;
	position: fixed;
	/* padding: 8px; */
	left: 76%;
	right: 0;
	box-sizing: border-box;
	border-left: 1px solid #dee2e6;
  box-shadow: -4px -2px 2px -4px #aaaaaa;
	height: 100vh;
  background-color: #fff;
	/* border-radius: 8px; */
}

@media only screen and (max-width: 600px) {
	.left-menu.cut {
		width: 100%;
	}
	.right-menu {
		left: 40%;
	}
}

.cut > .btn-step.next {
	right: 20%;
}

@media only screen and (max-width: 600px) {
	.cut > .btn-step.next {
		right: 40%;
	}
}

.learn-header-course{
  position: fixed;
  color: #f8f9fa;
  background: #212529;
  z-index: 1;
  width: 100%;
  height: 69px;
	padding: 0.5rem;
	border-bottom: 1px solid #dee2e6;
	-webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.btn-leave-course {
  cursor: pointer;
  font-size: 10pt;
}
.btn-leave-course:hover {
  color: #fff;
  font-weight: 600;
}

.btn-show-panel {
	position: fixed;
	margin-top: 48px;
	right: 0;
	z-index: 9999;
	color: #ced4da !important;
	border: 1.4px solid #ced4da !important;
}
.btn-show-panel:hover {
	color: #fff !important;
	background-color: #adb5bd !important;
	border: 1.4px solid #adb5bd !important;
}

.btn-hide-panel {
	position: absolute;
	right: 4px;
	font-size: 8pt !important;
	border: none !important;
	color: #000 !important;
}
.btn-hide-panel:hover {
	background-color: #dee2e6 !important;
}

.btn-step {
	position: absolute;
  visibility: visible;
	background-color: rgba(25, 135, 84, 0.7) !important;
	color: white !important;
	border: 1px solid rgba(25, 135, 84, 0.7) !important;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	margin-top: 210px;
	z-index: 9999;
}

.btn-step.prev {
	left: 0;
}
.btn-step.next {
	right: 0;
}

.btn-step.prev:hover {
	background-color: #198754 !important;
}
.btn-step.next:hover {
	background-color: #198754 !important;
}

.btn-step.prev:disabled {
	background-color: rgba(25, 135, 84, 0.5) !important;
	border: 1px solid rgba(25, 135, 84, 0.5) !important;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}
.btn-step.next:disabled {
	background-color: rgba(25, 135, 84, 0.5) !important;
	border: 1px solid rgba(25, 135, 84, 0.5) !important;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

.lms-old-stepper{
	background-color: white;
	color: #000;
	padding: 8px;
}
.lms-old-stepper.active{
	font-weight: 500;
	background-color: #dee2e6;
}
.lms-old-stepper.visited{
	cursor: pointer;
	padding: 6px 8px;
}
.lms-old-stepper.visited:hover{
	background-color: #dee2e6;
}
.lms-old-stepper.other{
	color: #adb5bd;
	padding: 4px 8px;
}

.learn-step-header {
	border-bottom: 1px solid #dee2e6;
	height: 50px;
	padding: 12px 8px;
}

.learn-step-main {
	height: calc(100% - 48px);
  overflow: auto;
  background: #f8f9fa;
}

.learn-step{
  padding: 4px 8px;
}

.learn-step.header{
  font-weight: 500;
  background: #f8f9fa;
  padding: 8px 6px;
}

.learn-step.parent{
	color: #000;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
  padding: 0 0 4px 0;
}
.learn-step.parent.inactive{
  cursor: default !important;
	color: #adb5bd;
}

.learn-step.child{
  font-size: 10.3pt;
}

.learn-step.child:hover{
	background-color: #dee2e6;
}
.learn-step.child.active{
	background-color: #dee2e6;
}

.sub-step-duration{
  font-size: 9pt;
  margin-top: 2px;
}

.learn-content{
  position: fixed;
  z-index: 1;
  margin-top: 69px;
  height: calc(100vh - 69px);
  padding: 8px;
  width: inherit;
  overflow: auto;
}

.content-title{
  margin-top: 8px;
}

.content-iframe{
  margin: -8px -8px 0 -8px;
  background: #212529;
  border-bottom: 1px solid #212529;
}

