@import '~@fortawesome/fontawesome-free/css/all.min.css';

html, body{
  overflow: auto;
}

body {
  margin: 0;
  width: 100%;
  min-height:100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bodyContent {
  padding-top: 52px;
  width: 100%;
  /* height: 100vh; */
}

.bodyContentLms {
  padding-top: 52px;
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .bodyContent2 {
    padding-top: 50px;
  }
  .bodyContent2 .loading-all {
    height: calc(100vh - 50px);
  }

  .testing-show {
    display: none;
  }
  .testing-hover.left:hover + .testing-show{
    display: none;
  }
  .testing-hover.right:hover + .testing-show{
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-nav>li>a {
  color: #9d9d9d;
}


.toast-message {
  font-size: 12px;
}

.datepicker {
  width: 100%;
}

/* .row {
  margin: 0px !important;
} */

.loading-all {
  position: relative;
}

.bodyContent .loading-all {
  height: calc(100vh - 52px);
}

.bodyContent2 .loading-all {
  height: calc(100vh - 90px);
}

.loading-all > div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .nav-link {
  white-space: nowrap;
} */

.dots {
  float: right;
  /* width: 34px; */
  /* height: 0px; */
  display: flex;
  font-size: 14pt;
  font-weight: 500;
  padding: 3px 8px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.dots:hover {
  cursor: pointer;
  border-radius: 15%;
  background: #dee2e6;
}

.dots-page{
  /* box-shadow: -1px 2px #dee2e6; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dots-item {
  padding: 4px 6px;
}

.dots-item:hover {
  cursor: pointer;
  background: #dee2e6;
}

.testing-container {
  position: absolute;
  /* right: 10%; */
  width: 200px;
  height: 200px;
}

.testing-container2 {
  position: absolute;
  right: 0;
  margin-right: 1.5rem;
  width: 200px;
  height: 200px;
}

.testing-hover {
  border: 1px solid #9d9d9d;
  position: relative;
  border-radius: 8px;
  width: 200px;
  height: 200px;
  align-items: center;
  text-align: center;
}

.testing-hover span {
  position: relative;
  top: 40%;
}

.testing-show {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s ease;
}
.testing-show2 {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s ease;
}

.testing-hover.right:hover + .tess>.testing-show {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 100%;
  transform: translate(0,-50%);
  margin-left: 21px;
  border: 1px solid #9d9d9d;
  background: white;
  opacity: 1;
  visibility: visible;
  border-radius: 8px;
  width: 100px;
  height: 150px;
  max-height: 600px;
}

.testing-hover.left:hover + .tess>.testing-show {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -121px;
  border: 1px solid #9d9d9d;
  background: white;
  opacity: 1;
  visibility: visible;
  border-radius: 8px;
  width: 100px;
  height: 400px;
  max-height: 600px;
}

.testing-hover.right:hover + .tess>.testing-show2 {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 100%;
  transform: translate(0,-50%);
  margin-left: 21px;
  border: 1px solid #9d9d9d;
  background: white;
  opacity: 1;
  visibility: visible;
  border-radius: 8px;
  width: 100px;
  height: 150px;
  max-height: 600px;
}

.testing-hover.left:hover + .tess>.testing-show2 {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -121px;
  border: 1px solid #9d9d9d;
  background: white;
  opacity: 1;
  visibility: visible;
  border-radius: 8px;
  width: 100px;
  height: 400px;
  max-height: 600px;
}

.arr-out{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s ease;
}

.arr-in{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s ease;
}

.testing-hover.right:hover + .tess>.arr-out {
  z-index: 99;
  position : absolute;
  top: 50%;
  margin-left: 2px;
  left: 100%;
  transform: translate(0,-50%);
  visibility: visible;
  opacity: 1;
  border-top    : 10px solid transparent;
  border-right  : 20px solid black;
  border-bottom : 10px solid transparent;
}

.testing-hover.right:hover + .tess>.arr-in {
  z-index: 100;
  position : absolute;
  top: 50%;
  left: 100%;
  transform: translate(0,-50%);
  margin-left: 3px;
  visibility: visible;
  opacity: 1;
  border-top    : 10px solid transparent;
  border-right  : 20px solid white;
  border-bottom : 10px solid transparent;
}



.testing-hover.left:hover + .tess>.arr-out {
  z-index: 99;
  position : absolute;
  top: 50%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -22px;
  border-top    : 10px solid transparent;
  border-left  : 20px solid black;
  border-right  : 0;
  border-bottom : 10px solid transparent;
}
.testing-hover.left:hover + .tess>.arr-in {
  z-index: 100;
  position : absolute;
  top: 50%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -23px;
  border-top    : 10px solid transparent;
  border-left  : 20px solid white;
  border-right  : 0;
  border-bottom : 10px solid transparent;
}

/* .testing-hover.left:hover + .arr-out {
  z-index: 100;
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -180px;
}
.testing-hover.left:hover + .arr-in {
  z-index: 100;
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0,-50%);
  margin-left: -180px;
  margin-top: 20px;
}

.arr-outer {
  z-index: 99;
  position : absolute;
  top: 50%;
  transform: translate(0,-50%);
  border-top    : 10px solid transparent;
  border-right  : 20px solid black;
  border-bottom : 10px solid transparent;
}
.arr-inner {
  z-index: 100;
  position : absolute;
  top: 50%;
  transform: translate(0,-50%);
  margin-left: 1px;
  border-top    : 10px solid transparent;
  border-right  : 20px solid blue;
  border-bottom : 10px solid transparent;
} */


.div1{
  display: none;
}
.div2{
  display: none;
}

.teste:hover +.show-hidden>.div1{
  display: block;
}
.teste:hover +.show-hidden>.div2{
  display: block;
}